<template>
    <div>
        <comp-table
            ref="comp_table"
            title="待同步数据"
            :columns="columns"
            :table-api="table_url"
            :is-selection="true"
            :delete-api="v => '/gateway/syinfopublish/api/pc/dynamicform/deleteDynamicById?dynamicId=' + v.id"
        >
            <template v-slot:operate>
                <Button type="primary" style="margin-right: 10px" @click="onDisplaySyncSetting">同步到居民基础数据库设置</Button>
                <Button type="primary" style="margin-right: 10px" @click="onBatchSync">批量同步</Button>
                <Button type="error" style="margin-right: 10px" @click="onJumpBack" v-if="backPath">返回</Button>
            </template>
        </comp-table>

        <CompModal class="formcollectionsync-setting" ref="sync_setting_modal" title="同步设置">
            <div class="form-box">
                <div class="form-item">
                    <div class="title">默认校验字段:</div>

                    <RadioGroup v-model="disabledGroup">
                        <Radio label="1">姓名&身份证号</Radio>
                        <Radio label="2">手机号&证件号码</Radio>
                        <Radio label="3">地址&证件号码</Radio>
                        <Radio label="4">姓名&地址</Radio>
                    </RadioGroup>
                </div>
            </div>

            <div class="table-box">
                <div class="base-box">
                    <div class="content-box">
                        <div class="head">
                            <div class="item" style="width:70px">序号</div>
                            <div class="item" style="width:200px">表单采集字段</div>
                            <div class="item" style="width:300px">对应匹配居民基础数据库转换字段</div>
                        </div>
                        <div class="body">
                            <div class="row" v-for="(item, idx) in formStructure" :key="item.fieldId">
                                <div class="column" style="width:70px">
                                    <div class="column-box">
                                        <p class="text-box">{{ idx + 1 }}</p>
                                    </div>
                                </div>
                                <div class="column" style="width:200px">
                                    <div class="column-box">
                                        <p class="text-box">{{ item.fieldName }}</p>
                                    </div>
                                </div>
                                <div class="column" style="width:300px">
                                    <div class="column-box">
                                        <div class="select-box" @click="onDisplaySelectField(item)">
                                            <p class="text text-box" v-if="item.mappingName">{{ item.mappingName }}</p>
                                            <p class="text placeholder" v-else>请选择</p>
                                            <div class="icon">
                                                <Icon type="ios-arrow-down" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p class="empty" v-if="!formStructure || 0 >= formStructure.length">当前数据为空</p>
                        </div>
                    </div>
                </div>
            </div>
            <template #operate="evt">
                <div class="operate-btns">
                    <div class="operate-btn highlight" @click="onSubmitSyncSetting">确定</div>
                    <div class="operate-btn" @click="evt.close">取消</div>
                </div>
            </template>
        </CompModal>

        <CompModal class="form-options-box" title="选择对应字段" ref="options_modal" @on-close="onCloseOptionsModal">
            <div class="options-box">
                <CheckboxGroup v-model="currentField" @on-change="onChangeCheckbox">
                    <div class="item-box step-item" v-for="(conf, idx) in formProperties" :key="idx">
                        <div class="item-base step-base" :class="'step-item-' + idx">
                            <div class="title-box">
                                <p class="serial-number">{{ idx + 1 }}</p>
                                <p class="name">{{ conf.formName }}</p>
                            </div>
                            <div class="step-content-box">
                                <!-- v-show="item.fieldType !== 'hidden'" -->
                                <div class="checkbox" v-for="(item, index) in conf.formOperates" :key="index">
                                    <Checkbox :label="item.fieldId">
                                        <span>{{ item.fieldName }}</span>
                                    </Checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </CheckboxGroup>
            </div>
            <template #operate="evt">
                <div class="operate-btns">
                    <div class="operate-btn highlight" @click="onSubmitSelectField">确定</div>
                    <div class="operate-btn" @click="evt.close">取消</div>
                </div>
            </template>
        </CompModal>
    </div>
</template>

<script>
import CompTable from "../jointly/components/comp-table.vue"
import CompModal from "@/views/residentdatabase/components/CompModal"

export default {
    components: { CompTable, CompModal },

    data() {
        let formId = this.$core.getUrlParam("formId")

        return {
            table_url: `/gateway/syinfopublish/api/pc/dynamicSync/selectDynamicSyncList?formId=${formId}&mappingType=1&orgCodeSelf=${parent.vue.loginInfo.userinfo.orgCode}`,
            // 表单ID
            formId: formId,
            // 表格结构
            columns: null,
            // 表单结构
            formStructure: null,

            formProperties: null,
            // 当前选择的字段
            currentField: null,
            // 返回链接
            backPath: this.$core.getUrlParam("back") || "",
        }
    },

    mounted() {
        this.getBasicForm()
        this.getSyncSetting()
    },

    methods: {
        getBasicForm() {
            return this.$get("/gateway/api/fastDev/AppForm/findFormByAppCode", {
                appCode: "UserInfo",
            }).then(res => {
                if (res.code == "200") {
                    this.formProperties = res.data.formProperties
                }
            })
        },

        /**
         * 获取同步设置数据
         */
        getSyncSetting() {
            this.$Message.loading({
                content: "加载中...",
                duration: 0,
            })

            return this.$get("/gateway/syinfopublish/api/pc/formMapping/selectFormFieldMapping", {
                formId: this.formId,
                mappingType: 1,
            })
                .then(res => {
                    if (res.code == 200) {
                        this.formStructure = res.data.mapping

                        const columns = []

                        for (let i = 0, mps = res.data.mapping; i < mps.length; i++) {
                            let v = mps[i]

                            columns.push({
                                title: v.mappingName,
                                key: v.mappingField,
                                minWidth: 200,
                                align: "center",
                            })
                        }

                        this.columns = columns
                    }
                })
                .finally(this.$Message.destroy)
        },

        /** 显示同步设置的弹窗 */
        onDisplaySyncSetting() {
            // 显示弹窗
            this.$refs.sync_setting_modal.display()
        },

        onDisplaySelectField(v) {
            this.activeField = v

            if (v.mappingField) {
                this.currentField = [v.mappingField]
            }

            this.$refs.options_modal.display()
        },

        onCloseOptionsModal() {
            this.currentField = null
            this.activeField = null
        },

        onChangeCheckbox(evt) {
            this.currentField = evt.length > 0 ? [evt[evt.length - 1]] : null
        },

        /**
         * 提交选择字段
         */
        onSubmitSelectField() {
            const af = this.activeField
            af.mappingField = this.currentField[0]
            af.mappingName = this.getFieldName(this.currentField[0])
            // 关闭弹窗
            this.$refs.options_modal.close()
        },

        /**
         * 获取字段名称
         */
        getFieldName(field) {
            for (let i = 0, fps = this.formProperties; i < fps.length; i++) {
                let v = fps[i]

                for (let fi = 0, fos = v.formOperates; fi < fos.length; fi++) {
                    let fv = fos[fi]

                    if (field === fv.fieldId) {
                        return fv.fieldName
                    }
                }
            }

            return ""
        },

        onSubmitSyncSetting() {
            if (!this.disabledGroup) {
                return this.$Message.warning("请选择校验字段")
            }

            var verifyRes = false

            switch (this.disabledGroup) {
                case "1":
                    verifyRes = this.verifyFieldExist("userName", "idNum")
                    break
                case "2":
                    verifyRes = this.verifyFieldExist("mobile", "idNum")
                    break
                case "3":
                    verifyRes = this.verifyFieldExist("houseCode", "idNum")
                    break
                case "4":
                    verifyRes = this.verifyFieldExist("userName", "houseCode")
                    break
            }

            if (!verifyRes) {
                return this.$Message.warning("校验失败，请检查转换字段中是否包含所选校验字段类型！")
            }

            this.$post(
                "/gateway/syinfopublish/api/pc/formMapping/saveFormFieldMapping",
                {
                    formId: this.formId,
                    mappingType: "1",
                    informationId: this.$core.getUrlParam("informationId") || "",
                    orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                    fieldList: this.formStructure,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success("提交成功")
                    // 关闭弹窗
                    this.$refs.sync_setting_modal.close()
                    // 刷新结构
                    this.getSyncSetting()
                }
            })
        },

        /**
         * 校验字段是否存在
         */
        verifyFieldExist(...fields) {
            for (let i = 0, l = this.formStructure; i < l.length; i++) {
                let idx = fields.indexOf(l[i].mappingField)

                if (idx > -1) {
                    fields.splice(idx, 1)
                }
            }

            return fields.length === 0
        },

        /**
         * 批量同步
         */
        onBatchSync() {
            const selection = this.$refs.comp_table.selection

            if (!selection || selection.length <= 0) {
                return this.$Message.warning("请勾选需要同步的数据")
            }

            this.$post(
                "/gateway/syinfopublish/api/pc/dynamicSync/dynamicBusiSyncData",
                {
                    formId: this.formId,
                    mappingType: 1,
                    syncData: selection,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 200) {
                    this.$Message.success("同步成功")
                    // 刷新表格
                    this.$refs.comp_table.onRefresh()
                } else {
                    this.$Message.error(res.desc)
                }
            })
        },

        onJumpBack() {
            this.$router.push(this.backPath)
        },
    },
}
</script>

<style lang="less" scoped>
.table-left {
    float: left;
    width: 160px;
    /deep/.ivu-menu {
        width: 160px !important;
    }
}
.none-modal {
    /deep/.ivu-modal-close {
        display: none;
    }
}

.formcollectionsync-setting {
    .form-box {
        .form-item {
            height: 70px;
            padding: 0 20px;
            display: flex;
            align-items: center;

            .title {
                margin-right: 10px;
            }

            .ivu-radio-wrapper {
                margin-right: 15px;
            }
        }
    }

    .table-box {
        position: relative;
        width: 100%;
        height: ~"calc(100% - 70px)";
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        display: flex;
        flex-grow: 1;

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        .base-box {
            min-width: 100%;
            flex-shrink: 0;
            display: flex;
            align-items: flex-start;

            .content-box {
                min-height: 100%;
                min-width: 100%;
                padding: 0 5px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .head {
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;
                    min-width: 100%;
                    height: 60px;
                    background: #f3f3f3;
                    z-index: 10;
                    border-radius: 5px;
                    display: flex;

                    .item {
                        padding: 0 20px;
                        width: 200px;
                        height: 100%;
                        font-size: 15px;
                        color: #abb4b9;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .body {
                    min-width: 100%;
                    max-width: 100%;

                    .row {
                        position: relative;
                        height: 50px;
                        z-index: 1;
                        border: 1px solid transparent;
                        border-radius: 5px;
                        display: flex;
                        -webkit-transition: all 0.3s;
                        transition: all 0.3s;
                        -webkit-transition-timing-function: ease;
                        transition-timing-function: ease;
                        box-sizing: border-box;

                        &:hover {
                            z-index: 5;
                            border-color: #e3e3e3;
                            box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
                        }

                        &:nth-child(2n) {
                            background: #f9f9f9;
                        }

                        .column {
                            padding: 0 20px;
                            width: 200px;
                            height: 100%;

                            .column-box {
                                margin: 3px 0;
                                width: 100%;
                                height: ~"calc(100% - 3px)";
                                letter-spacing: 1px;
                                color: #000;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                overflow-x: auto;
                                overflow-y: hidden;
                                -webkit-overflow-scrolling: touch;

                                &::-webkit-scrollbar {
                                    height: 5px;
                                }

                                .text-box {
                                    font-size: 13px;
                                    max-width: 90%;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                .placeholder {
                                    color: #888;
                                }

                                &:hover .text-box {
                                    overflow: initial;
                                    text-overflow: initial;
                                }

                                .select-box {
                                    cursor: pointer;
                                    position: relative;
                                    padding: 0 10px;
                                    width: 150px;
                                    border: 1px solid #e3e3e3;
                                    border-radius: 5px;
                                    height: 35px;
                                    display: flex;
                                    align-items: center;

                                    .text {
                                        flex-grow: 1;
                                    }

                                    .icon {
                                        margin-left: 10px;
                                        font-size: 16px;
                                        flex-shrink: 0;
                                    }
                                }

                                .normal-c {
                                    color: #07cd6f;
                                }

                                .normal-bg {
                                    background: #07cd6f;
                                }

                                .empty-c {
                                    color: #ffbb3a;
                                }

                                .empty-bg {
                                    background: #ffbb3a;
                                }

                                .subclass-box {
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    -webkit-transition: all 0.3s;
                                    transition: all 0.3s;
                                    -webkit-transition-timing-function: ease;
                                    transition-timing-function: ease;

                                    .point {
                                        width: 8px;
                                        height: 8px;
                                        border-radius: 100%;
                                    }

                                    .text {
                                        margin-left: 10px;
                                    }
                                }
                            }

                            .column-box-null {
                                color: #666;
                            }
                        }
                    }

                    .empty {
                        text-align: center;
                        font-size: 15px;
                        color: #888;
                        line-height: 25px;
                        position: absolute;
                        top: 60px;
                        right: 0;
                        left: 0;
                    }
                }
            }

            .operating-box {
                background: #fff;
                z-index: 10;
                min-height: 100%;
                padding-right: 5px;
                flex-grow: 1;
                flex-shrink: 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .operating-title {
                    position: sticky;
                    top: 0;
                    min-width: 100%;
                    padding: 0 30px;
                    height: 60px;
                    font-size: 15px;
                    color: #abb4b9;
                    font-weight: bold;
                    background: #f3f3f3;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    border-radius: 5px;
                }

                .operating-item {
                    padding: 0 30px;
                    height: 50px;
                    letter-spacing: 1px;
                    color: #000;
                    min-width: 100%;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;

                    &:nth-child(2n-1) {
                        background: #f9f9f9;
                    }

                    .button-box {
                        margin-right: 10px;

                        &:last-child {
                            margin-right: 0;
                        }

                        a,
                        button {
                            cursor: pointer;
                            padding: 5px 12px;
                            color: #fff;
                            font-size: 14px;
                            border: 0;
                            display: flex;
                            -webkit-transition: all 0.3s;
                            transition: all 0.3s;
                            -webkit-transition-timing-function: ease;
                            transition-timing-function: ease;
                            border-radius: 5px;

                            &:hover {
                                box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
                            }
                        }
                    }

                    .edit {
                        background: #00b3d9;
                    }

                    .delete {
                        background: #ff5722;
                    }

                    .entity {
                        background: #2faaf7;
                    }
                }
            }

            .operating-suspension {
                position: sticky;
                right: 0;
                box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);

                .operating-title {
                    border-radius: 0 5px 5px 0;
                }
            }
        }
    }
}

.form-options-box {
    .options-box {
        width: 100%;
        height: 100%;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;

        .item-box {
            padding: 0 20px 40px 30px;

            &:first-child {
                padding-top: 40px;
            }

            > .item-base {
                > .title-box {
                    width: 100%;
                    display: flex;
                    align-items: center;

                    .serial-number {
                        width: 40px;
                        height: 40px;
                        font-size: 20px;
                        color: #36414e;
                        border: 1px solid #e3e3e3;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .name {
                        font-size: 25px;
                        margin-left: 20px;
                        color: #36414e;
                    }
                }

                .step-content-box {
                    margin: 20px 0 0 52px;
                    display: flex;
                    flex-wrap: wrap;

                    .checkbox {
                        margin: 8px;
                    }

                    .form-box {
                        display: flex;
                        flex-wrap: wrap;

                        .form-item {
                            width: 300px;
                            margin: 10px 40px 10px 0;

                            .name-box {
                                font-size: 15px;
                                margin-bottom: 5px;
                                color: #888;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
